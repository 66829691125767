import { TripPayment } from './payments';

export type TripsState = {
  departureTrip: CurrentTrip;
  returnTrip: CurrentTrip;
  freeTickets: FreeTickets | null;
};

export type CurrentTrip = {
  isTripSelected: boolean;
  tripDetails: TripResponse | null;
  busModel: Connection[];
};

export type TripName = 'departureTrip' | 'returnTrip';

export type FreeTickets = {
  [key: string]: FreeTicketsPayload[];
};

export type FreeTicketsPayload = {
  tripId: string;
  userId: string;
  ticket: Ticket;
};

export type CleanFreeTicketsPayload = {
  tripId: string;
  ticketId: string;
};

export type GetStationsPayload = {
  departureLocationId: string;
  arrivalLocationId: string;
  departureAt: string;
  controlNumber: string;
  connection: boolean;
};

export type Station = {
  companyName: string;
  controlNumber: string;
  sections: Section[];
};

export type Section = {
  departureTime: string;
  departureLocation: Location;
  arrivalTime: string;
  arrivalLocation: Location;
  arrivalDay: number;
  duration: string;
};

type Location = {
  code: string;
  id: string;
  name: string;
  description?: string;
};

export type Connection = Omit<BaseTrip, 'bpe'> & {
  seats: Seat[];
  extras: BusExtra[];
  currencyCode: string;
  connectionIndex: number;
};

type TripInformation = {
  departureLocationId: string;
  arrivalLocationId: string;
  departureAt: string;
  arrivalAt: string;
  departureAtLocal: string;
  arrivalAtLocal: string;
  controlNumber: string;
};

export type GetTripsPayload = {
  departureDate: string;
  departureLocation: string;
  arrivalLocation: string;
};

export type DeleteTripPayload = {
  id: string;
};

export type GetTicketsPayload = {
  bookingId?: string;
  email?: string;
  departureLocationId?: string;
  arrivalLocationId?: string;
  departureAtLocal?: string;
  ids?: string[];
};

export type CreateTripPayload = TripInformation & {
  passengerAmount: number;
  return: boolean;
  paymentId?: string;
  classOfService: string;
  price: string;
  childPrice: string;
  babyPrice: string;
  ticketsAttributes: (TripInformation & {
    passengerType: string;
    connectionIndex: number;
    tripPrice: string;
  })[];
};

export type GetTripPayload = {
  id: string;
  userId: string;
  connectionIndex: number;
};

export type MakeReservationPayload = {
  id: string;
  userId: string;
  ticketsAttributes: Ticket[];
};

export type TripResponse = {
  id: string;
  price: string;
  return: boolean;
  passengerAmount?: number;
  departureAt: string;
  arrivalAt: string;
  departureAtLocal: string;
  arrivalAtLocal: string;
  controlNumber: string;
  classOfService: string;
  babyPrice: string;
  childPrice: string;
  arrivalLocation: TripLocation;
  departureLocation: TripLocation;
  pendingConnection: Connection | null;
  payment: TripPayment;
  tickets: Ticket[];
  allowsSeatSelection?: boolean;
  allowsCancelling?: boolean;
  externalInvoice?: boolean;
  allowsRebooking?: boolean;
};

export type DeleteTripResponse = Omit<
  TripResponse,
  'departureLocation' | 'arrivalLocation' | 'pendingConnection' | 'tickets'
> & {
  departureLocationId: number;
  arrivalLocationId: number;
  paymentId: string;
};

export type BaseTrip = {
  controlNumber: string;
  departureTime: string;
  departureLocation: Location;
  arrivalTime: string;
  arrivalLocation: Location;
  arrivalDay: number;
  idCompany: number;
  companyName: string;
  classOfServiceName: string;
  bpe: boolean;
};

export type Trip = BaseTrip & {
  priceValue: string;
  hasConnection: boolean;
  availableSeats: number;
  connectionRoutes: BaseTrip[];
  currencyCode: string;
  prices: Price[];
  logo_company: string;
  duration?: string;
  allowsSeatSelection?: boolean;
  allowsCancelling?: boolean;
  shouldShowIncentive?: boolean;
  numberOfPurchases?: number;
  allowsRebooking?: boolean;
  gipsyyBusModal?: boolean;
  hideModal?: boolean;
};

export enum PassengerName {
  BABY = 'BABY',
  CHILD = 'CHILD',
  ADULT = 'ADULT',
}

export type Price = {
  priceValue: string;
  passengerType: number;
  passengerTypeName: PassengerName;
  avaliableSeats: number;
};

export type Seat = {
  seatIdentifier: string;
  row: number;
  cell: number;
  level: number;
  isUnavailable: boolean;
  pricingIdentifier: number | null;
  price: string;
};

export type BusExtra = {
  extraType: string;
  row: number;
  cell: number;
  level: number;
};

export type TripLocation = {
  name: string;
  externalId: string;
  description: string;
  latitude: number;
  longitude: number;
};

export type TripsPayload = {
  departureDate: string;
  departureLocation: number;
  arrivalLocation: number;
};

export enum PassengerType {
  BABY = 'baby',
  CHILD = 'child',
  ADULT = 'adult',
}

export type Ticket = Omit<TripInformation, 'arrivalLocationId' | 'departureLocationId' | 'controlNumber'> & {
  id: string;
  passengerName: string | null;
  passengerDocumentNumber: string | null;
  passengerDocumentType: string | null;
  passengerType: PassengerType;
  price: string;
  tripPrice: string;
  seatIdentifier: string | null;
  connectionIndex: number;
  arrivalLocation: TripLocation;
  departureLocation: TripLocation;
  qrCodeUrl?: string;
  number?: number;
  ticketNumber?: string;
  status?: string;
  bookingId?: string;
  isMultibanco?: boolean;
  isMbway?: boolean;
  companyName?: string; 
  externalInvoice?: boolean;
  invoiceUrl?: string;
};
